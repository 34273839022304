<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">

    </PageMenu>

    <div class="page-with-menu">
      <div v-if="template">
        <b-card class="mb-5">
          <b-card-text>
            <TemplateForm :template="template"/>
          </b-card-text>
        </b-card>

        <TemplateTranslations :template="template"/>
      </div>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
    components: {
      PageMenu: () => import('@/common/components/PageMenu'),
      TemplateForm: () => import('./../../components/templates/Template.Form'),
      TemplateTranslations: () => import('./../../components/templates/Template.Translations')
    },
    data(){
      return {
        page: {
            title: "Manage Email Template",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Emails",
                url: this.$router.resolve({name: "emails"}).href
            },
            {
              title: "Email Templates",
              url: this.$router.resolve({name: "emails.templates"}).href
            }
        ],
        template: null
      }
    },
    methods: {
      getTemplate(templateId){
        this.$api.get(`emails/template/${templateId}`).then(({data}) => {
          this.template = data;
        });
      },
    },
    mounted(){
      this.getTemplate(this.$route.params.templateId);
      this.$emit('set-sidebar',{active: "emails"});
    },
};
</script>
